export default [
  {
    path: '/supplier',
    name: 'list-supplier',
    component: () => import('@/views/supplier/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.supplier',
      resource: 'supplier',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSupplier',
          active: true,
        },
      ],
    },
  },
  {
    path: '/supplier/create',
    name: 'create-supplier',
    component: () => import('@/views/supplier/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.supplier',
      navActiveLink: 'list-supplier',
      resource: 'supplier',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listSupplier',
          to: { name: 'list-supplier' }
        },
        {
          text: 'breadcrumb.createSupplier',
          active: true,
        },
      ],
    },
  },
  {
    path: '/supplier/:id/update',
    name: 'update-supplier',
    component: () => import('@/views/supplier/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.supplier',
      navActiveLink: 'list-supplier',
      resource: 'supplier',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSupplier',
          to: { name: 'list-supplier' }
        },
        {
          text: 'breadcrumb.updateSupplier',
          active: true,
        },
      ],
    },
  },
  {
    path: '/supplier/:id',
    name: 'view-supplier',
    component: () => import('@/views/supplier/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.supplier',
      navActiveLink: 'list-supplier',
      resource: 'supplier',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSupplier',
          to: { name: 'list-supplier' }
        },
        {
          text: 'breadcrumb.viewSupplier',
          active: true,
        },
      ],
    },
  },
]
