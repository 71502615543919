export default [
  {
    path: '/stock-admin',
    name: 'list-stock-admin',
    component: () => import('@/views/stockAdmin/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockAdmin',
      resource: 'stock-admin',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listStockAdmin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-admin/create',
    name: 'create-stock-admin',
    component: () => import('@/views/stockAdmin/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockAdmin',
      navActiveLink: 'list-stock-admin',
      resource: 'stock-admin',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listStockAdmin',
          to: { name: 'list-stock-admin' }
        },
        {
          text: 'breadcrumb.createStockAdmin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-admin/:id/update',
    name: 'update-stock-admin',
    component: () => import('@/views/stockAdmin/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockAdmin',
      navActiveLink: 'list-stock-admin',
      resource: 'stock-admin',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listStockAdmin',
          to: { name: 'list-stock-admin' }
        },
        {
          text: 'breadcrumb.updateStockAdmin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-admin/:id',
    name: 'view-stock-admin',
    component: () => import('@/views/stockAdmin/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockAdmin',
      navActiveLink: 'list-stock-admin',
      resource: 'stock-admin',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listStockAdmin',
          to: { name: 'list-stock-admin' }
        },
        {
          text: 'breadcrumb.viewStockAdmin',
          active: true,
        },
      ],
    },
  },
]
