export default [
  {
    path: '/item-type',
    name: 'list-item-type',
    component: () => import('@/views/itemType/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.itemType',
      resource: 'item-type',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listItemType',
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-type/create',
    name: 'create-item-type',
    component: () => import('@/views/itemType/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.itemType',
      navActiveLink: 'list-item-type',
      resource: 'item-type',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listItemType',
          to: { name: 'list-item-type' }
        },
        {
          text: 'breadcrumb.createItemType',
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-type/:id/update',
    name: 'update-item-type',
    component: () => import('@/views/itemType/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.itemType',
      navActiveLink: 'list-item-type',
      resource: 'item-type',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listItemType',
          to: { name: 'list-item-type' }
        },
        {
          text: 'breadcrumb.updateItemType',
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-type/:id',
    name: 'view-item-type',
    component: () => import('@/views/itemType/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.itemType',
      navActiveLink: 'list-item-type',
      resource: 'item-type',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listItemType',
          to: { name: 'list-item-type' }
        },
        {
          text: 'breadcrumb.viewItemType',
          active: true,
        },
      ],
    },
  },
]
