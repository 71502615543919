export default [
    {
      path: '/purchase-request-report',
      name: 'list-purchase-request-report',
      component: () => import('@/views/purchaseRequestReport/List.vue'),
      meta: {
        pageTitle: 'breadcrumb.purchaseRequestReport',
        resource: 'purchase-request-report',
        action: 'read',
        breadcrumb: [
          {
            text: 'breadcrumb.purchaseRequestReport',
            active: true,
          },
        ],
      },
    },
    {
      path: '/purchase-request-report/create',
      name: 'create-purchase-request-report',
      component: () => import('@/views/purchaseRequestReport/Create.vue'),
      meta: {
        pageTitle: 'breadcrumb.purchaseRequestReport',
        navActiveLink: 'list-purchase-request-report',
        resource: 'purchase-request-report',
        action: 'create',
        breadcrumb: [
          {
            text: 'breadcrumb.purchaseRequestReport',
            to: { name: 'list-purchase-request-report' }
          },
          {
            text: 'breadcrumb.createItem',
            active: true,
          },
        ],
      },
    },
    {
      path: '/purchase-request-report/:id',
      name: 'view-purchase-request-report',
      component: () => import('@/views/purchaseRequestReport/View.vue'),
      meta: {
        pageTitle: 'breadcrumb.purchaseRequestReport',
        navActiveLink: 'list-purchase-request-report',
        resource: 'purchase-request-report',
        action: 'read',
        breadcrumb: [
          {
            text: 'breadcrumb.purchaseRequestReport',
            to: { name: 'list-purchase-request-report' }
          },
          {
            text: 'breadcrumb.viewItem',
            active: true,
          },
        ],
      },
    },
  ]
  