export default [
  {
    path: '/stock',
    name: 'list-stock',
    component: () => import('@/views/stock/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.stock',
      resource: 'stock',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listStock',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock/update',
    name: 'update-stock',
    component: () => import('@/views/stock/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.stock',
      navActiveLink: 'list-stock',
      resource: 'stock',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listStock',
          to: { name: 'list-stock' }
        },
        {
          text: 'breadcrumb.updateStock',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock/:id',
    name: 'view-stock',
    component: () => import('@/views/stock/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.stock',
      navActiveLink: 'list-stock',
      resource: 'stock',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listStock',
          to: { name: 'list-stock' }
        },
        {
          text: 'breadcrumb.viewStock',
          active: true,
        },
      ],
    },
  },
]
