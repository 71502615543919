import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/purchase-request`;

export default {
  index(payload) {
    let params = prepareFilterParams(payload);
    params = {
      ...params,
      ...payload,
    };

    return Client.post(resource, params, {
      headers: {
        action: "list",
      },
    });
  },
  show(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "show",
      },
    });
  },
  create(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "store",
      },
    });
  },
  exportExcel(id) {

    return Client.post(resource, {
      id: id
    }, {
      headers: {
        action: "export-excel-purchase-request-report",
      },
      responseType: "blob"
    });
  },
  exportPdf(id) {

    return Client.post(resource, { 
      id: id
    }, {
      headers: {
        action: "export-pdf-purchase-request-report",
      },
      responseType: "blob"
    });
  },
};
