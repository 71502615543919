export default [
  {
    path: '/item-section',
    name: 'list-section',
    component: () => import('@/views/itemSection/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.section',
      resource: 'item-section',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listItemSection',
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-section/create',
    name: 'create-item-section',
    component: () => import('@/views/itemSection/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.itemSection',
      navActiveLink: 'list-section',
      resource: 'item-section',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listItemSection',
          to: { name: 'list-section' }
        },
        {
          text: 'breadcrumb.createItemSection',
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-section/:id/update',
    name: 'update-item-section',
    component: () => import('@/views/itemSection/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.itemSection',
      navActiveLink: 'list-section',
      resource: 'item-section',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSection',
          to: { name: 'list-section' }
        },
        {
          text: 'breadcrumb.updateSection',
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-section/:id',
    name: 'view-item-section',
    component: () => import('@/views/itemSection/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.itemSection',
      navActiveLink: 'list-section',
      resource: 'item-section',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listItemSection',
          to: { name: 'list-section' }
        },
        {
          text: 'breadcrumb.viewItemSection',
          active: true,
        },
      ],
    },
  },
]
