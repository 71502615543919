import Config from "@/repositories/Clients/AxiosDefaultConfig";

export default {
    namespaced: true,
    state: {
        perPage: Config.perPage,
        perPageOptions: Config.perPageOptions,
    },
    getters: {},
    mutations: {
      SET_PER_PAGE(state, val) {
        state.perPage = val
      },
    },
    actions: {},
  }
  