import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import errors from './routes/errors';
import auth from './routes/auth';
import profile from './routes/profile';
import user from './routes/user';
import role from './routes/role';
import employee from './routes/employee';
import company from './routes/company';
import department from './routes/department';
import position from './routes/position';
import unit from './routes/unit';
import item from './routes/item';
import itemCategory from './routes/itemCategory';
import itemSection from './routes/itemSection';
import stockLocation from './routes/stockLocation';
import itemType from './routes/itemType';
import stockBin from './routes/stockBin';
import requestPurpose from './routes/requestPurpose';
import requestSection from './routes/requestSection';
import supplier from './routes/supplier';
import stockAdmin from './routes/stockAdmin';
import stock from './routes/stock';
import stockRequest from './routes/stockRequest';
import inventoryReport from './routes/inventoryReport';
import stockReport from './routes/stockReport';
import purchaseRequestReport from './routes/purchasaeRequestReport';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'breadcrumb.dashboard',
        resource: 'home',
        breadcrumb: [
          {
            text: 'breadcrumb.dashboard',
            active: true,
          },
        ],
      },
    },
    ...errors,
    ...auth,
    ...profile,
    ...user,
    ...role,
    ...employee,
    ...company,
    ...department,
    ...position,
    ...unit,
    ...item,
    ...itemCategory,
    ...itemSection,
    ...stockLocation,
    ...itemType,
    ...stockBin,
    ...requestPurpose,
    ...requestSection,
    ...supplier,
    ...stockAdmin,
    ...stock,
    ...stockRequest,
    ...inventoryReport,
    ...stockReport,
    ...purchaseRequestReport,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Method using PUSH
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch(err => err)
}

// Method using Replace
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch(err => err)
}

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? 'admin' : null))
  }

  return next()
})

export default router
