export default [
  {
    path: '/stock-bin',
    name: 'list-stock-bin',
    component: () => import('@/views/stockBin/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockBin',
      resource: 'stock-bin',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-bin/create',
    name: 'create-stock-bin',
    component: () => import('@/views/stockBin/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockBin',
      navActiveLink: 'list-stock-bin',
      resource: 'stock-bin',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listBin',
          to: { name: 'list-stock-bin' }
        },
        {
          text: 'breadcrumb.createBin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-bin/:id/update',
    name: 'update-stock-bin',
    component: () => import('@/views/stockBin/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockBin',
      navActiveLink: 'list-stock-bin',
      resource: 'stock-bin',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBin',
          to: { name: 'list-stock-bin' }
        },
        {
          text: 'breadcrumb.updateBin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-bin/:id',
    name: 'view-stock-bin',
    component: () => import('@/views/stockBin/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockBin',
      navActiveLink: 'list-stock-bin',
      resource: 'stock-bin',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBin',
          to: { name: 'list-stock-bin' }
        },
        {
          text: 'breadcrumb.viewBin',
          active: true,
        },
      ],
    },
  },
]
