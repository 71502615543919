export default [
  {
    path: '/unit',
    name: 'list-unit',
    component: () => import('@/views/unit/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.unit',
      resource: 'unit',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listUnit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/unit/create',
    name: 'create-unit',
    component: () => import('@/views/unit/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.unit',
      navActiveLink: 'list-unit',
      resource: 'unit',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listUnit',
          to: { name: 'list-unit' }
        },
        {
          text: 'breadcrumb.createUnit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/unit/:id/update',
    name: 'update-unit',
    component: () => import('@/views/unit/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.unit',
      navActiveLink: 'list-unit',
      resource: 'unit',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listUnit',
          to: { name: 'list-unit' }
        },
        {
          text: 'breadcrumb.updateUnit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/unit/:id',
    name: 'view-unit',
    component: () => import('@/views/unit/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.unit',
      navActiveLink: 'list-unit',
      resource: 'unit',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listUnit',
          to: { name: 'list-unit' }
        },
        {
          text: 'breadcrumb.viewUnit',
          active: true,
        },
      ],
    },
  },
]
