export default {
    namespaced: true,
    state: {
        id: null,
        name: null,
        username: null,
        image: null,
        typeId: null,
        position: {},
    },
    getters: {},
    mutations: {
        UPDATE_PROFILE(state, val) {
            state.id = val.id;
            state.name = val.name;
            state.username = val.username;
            state.image = val.image;
            state.typeId = val.typeId;
            state.position = val.position;
        },
    },
    actions: {},
}
