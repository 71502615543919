export default [
  {
    path: '/request-purpose',
    name: 'list-request-purpose',
    component: () => import('@/views/requestPurpose/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.requestPurpose',
      resource: 'request-purpose',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listRequestPurpose',
          active: true,
        },
      ],
    },
  },
  {
    path: '/request-purpose/create',
    name: 'create-request-purpose',
    component: () => import('@/views/requestPurpose/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.requestPurpose',
      navActiveLink: 'list-request-purpose',
      resource: 'request-purpose',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listRequestPurpose',
          to: { name: 'list-request-purpose' }
        },
        {
          text: 'breadcrumb.createRequestPurpose',
          active: true,
        },
      ],
    },
  },
  {
    path: '/request-purpose/:id/update',
    name: 'update-request-purpose',
    component: () => import('@/views/requestPurpose/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.requestPurpose',
      navActiveLink: 'list-request-purpose',
      resource: 'request-purpose',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listRequestPurpose',
          to: { name: 'list-request-purpose' }
        },
        {
          text: 'breadcrumb.updateRequestPurpose',
          active: true,
        },
      ],
    },
  },
  {
    path: '/request-purpose/:id',
    name: 'view-request-purpose',
    component: () => import('@/views/requestPurpose/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.requestPurpose',
      navActiveLink: 'list-request-purpose',
      resource: 'request-purpose',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listRequestPurpose',
          to: { name: 'list-request-purpose' }
        },
        {
          text: 'breadcrumb.viewRequestPurpose',
          active: true,
        },
      ],
    },
  },
]
