export default [
  {
    path: '/inventory-report',
    name: 'list-inventory-report',
    component: () => import('@/views/inventoryReport/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.inventoryReport',
      resource: 'inventory-report',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.inventoryReport',
          active: true,
        },
      ],
    },
  },
]
