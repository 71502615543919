export default [
  {
    path: '/request-section',
    name: 'list-request-section',
    component: () => import('@/views/requestSection/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.requestSection',
      resource: 'request-section',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listRequestSection',
          active: true,
        },
      ],
    },
  },
  {
    path: '/request-section/create',
    name: 'create-request-section',
    component: () => import('@/views/requestSection/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.requestSection',
      navActiveLink: 'list-request-section',
      resource: 'request-section',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listRequestSection',
          to: { name: 'list-request-section' }
        },
        {
          text: 'breadcrumb.createRequestSection',
          active: true,
        },
      ],
    },
  },
  {
    path: '/request-section/:id/update',
    name: 'update-request-section',
    component: () => import('@/views/requestSection/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.requestSection',
      navActiveLink: 'list-request-section',
      resource: 'request-section',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listRequestSection',
          to: { name: 'list-request-section' }
        },
        {
          text: 'breadcrumb.updateRequestSection',
          active: true,
        },
      ],
    },
  },
  {
    path: '/request-section/:id',
    name: 'view-request-section',
    component: () => import('@/views/requestSection/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.requestSection',
      navActiveLink: 'list-request-section',
      resource: 'request-section',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listRequestSection',
          to: { name: 'list-request-section' }
        },
        {
          text: 'breadcrumb.viewRequestSection',
          active: true,
        },
      ],
    },
  },
]
