import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import store from '@/store';

const resource = `${Config.basePath}/v1/admin/report`;

export default {
  index(payload) {
    payload.limit = store.state.pagination.perPage;

    return Client.post(resource, payload, {
      headers: {
        action: "inventory-report",
      },
    });
  },
  exportExcel(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "export-excel-inventory-report",
      },
      responseType: "blob"
    });
  },
  exportPdf(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "export-pdf-inventory-report",
      },
      responseType: "blob"
    });
  },
};
