export default [
    {
      path: '/stock-report',
      name: 'list-stock-report',
      component: () => import('@/views/stockReport/List.vue'),
      meta: {
        pageTitle: 'breadcrumb.stockReport',
        resource: 'stock-report',
        action: 'read',
        breadcrumb: [
          {
            text: 'breadcrumb.stockReport',
            active: true,
          },
        ],
      },
    },
  ]
  