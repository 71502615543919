export default [
  {
    path: '/item-category',
    name: 'list-category',
    component: () => import('@/views/itemCategory/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.itemCategory',
      resource: 'item-category',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listItemCategory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-category/create',
    name: 'create-item-category',
    component: () => import('@/views/itemCategory/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.itemCategory',
      navActiveLink: 'list-category',
      resource: 'item-category',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listItemCategory',
          to: { name: 'list-category' }
        },
        {
          text: 'breadcrumb.createItemCategory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-category/:id/update',
    name: 'update-item-category',
    component: () => import('@/views/itemCategory/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.itemCategory',
      navActiveLink: 'list-category',
      resource: 'item-category',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listItemCategory',
          to: { name: 'list-category' }
        },
        {
          text: 'breadcrumb.updateItemCategory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/item-category/:id',
    name: 'view-item-category',
    component: () => import('@/views/itemCategory/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.itemCategory',
      navActiveLink: 'list-category',
      resource: 'item-category',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listItemCategory',
          to: { name: 'list-category' }
        },
        {
          text: 'breadcrumb.viewItemCategory',
          active: true,
        },
      ],
    },
  },
]
