export default [
  {
    path: '/stock-location',
    name: 'list-stock-location',
    component: () => import('@/views/stockLocation/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockLocation',
      resource: 'stock-location',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listLocation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-location/create',
    name: 'create-stock-location',
    component: () => import('@/views/stockLocation/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockLocation',
      navActiveLink: 'list-stock-location',
      resource: 'stock-location',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listLocation',
          to: { name: 'list-stock-location' }
        },
        {
          text: 'breadcrumb.createLocation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-location/:id/update',
    name: 'update-stock-location',
    component: () => import('@/views/stockLocation/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockLocation',
      navActiveLink: 'list-stock-location',
      resource: 'stock-location',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listLocation',
          to: { name: 'list-stock-location' }
        },
        {
          text: 'breadcrumb.updateLocation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stock-location/:id',
    name: 'view-stock-location',
    component: () => import('@/views/stockLocation/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.stockLocation',
      navActiveLink: 'list-stock-location',
      resource: 'stock-location',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listLocation',
          to: { name: 'list-stock-location' }
        },
        {
          text: 'breadcrumb.viewLocation',
          active: true,
        },
      ],
    },
  },
]
