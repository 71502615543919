import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams, prepareListOptionParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/upload`;

export default {
  upload(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "upload-base64",
      },
    });
  },
};
