import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams, prepareOptionFilterParams, } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/item`;

export default {
  index(payload) {
    let queries = [];
    if (payload.stockType) {
      if (payload.stockType == 1) {
        queries = [{
          searchby: "quantity",
          searchoperator: ">",
          search: 0
        }];
      } else if (payload.stockType == 2) {
        queries = [{
          searchby: "quantity",
          searchoperator: ">",
          search: 0
        }, {
          searchby: "quantity",
          searchoperator: "f<",
          search: "reorder_level"
        }];
      } else if (payload.stockType == 3) {
        queries = [{
          searchby: "quantity",
          searchoperator: "=",
          search: 0
        }];
      }

      payload.stockType = null;
    }

    const params = prepareFilterParams(payload, queries);
    return Client.post(resource, {
      ...params
    }, {
      headers: {
        action: "list",
      },
    });
  },
  show(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "show",
      },
    });
  },
  create(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "store",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "update",
      },
    });
  },
  options(payload) {
    const queries = [{
      searchby: "item_code",
      searchoperator: "%",
      search: payload.search
    }, {
      searchby: "name",
      searchoperator: "%",
      search: payload.search
    }];
    const params = prepareOptionFilterParams(payload, queries, "OR");

    return Client.post(resource, params, {
      headers: {
        action: "option",
      },
    });
  },
  destroy(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "delete",
      },
    });
  },
  restore(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "restore",
      },
    });
  },
};
