export default [
  {
    path: '/employee',
    name: 'list-employee',
    component: () => import('@/views/employee/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.employee',
      resource: 'employee',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listEmployee',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employee/organizational-chart',
    name: 'chart-employee',
    component: () => import('@/views/employee/orgranizationChart/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.employee',
      navActiveLink: 'list-employee',
      resource: 'employee',
      action: 'chart',
      breadcrumb: [
        {
          text: 'breadcrumb.listEmployee',
          to: { name: 'list-employee' }
        },
        {
          text: 'breadcrumb.organizationalChart',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employee/create',
    name: 'create-employee',
    component: () => import('@/views/employee/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.employee',
      navActiveLink: 'list-employee',
      resource: 'employee',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listEmployee',
          to: { name: 'list-employee' }
        },
        {
          text: 'breadcrumb.createEmployee',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employee/:id/update',
    name: 'update-employee',
    component: () => import('@/views/employee/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.employee',
      navActiveLink: 'list-employee',
      resource: 'employee',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listEmployee',
          to: { name: 'list-employee' }
        },
        {
          text: 'breadcrumb.updateEmployee',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employee/:id',
    name: 'view-employee',
    component: () => import('@/views/employee/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.employee',
      navActiveLink: 'list-employee',
      resource: 'employee',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listEmployee',
          to: { name: 'list-employee' }
        },
        {
          text: 'breadcrumb.viewEmployee',
          active: true,
        },
      ],
    },
  },
]
